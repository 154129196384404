body {
  margin: 0;
  padding: 0;
  /* background-image: linear-gradient(120deg, #011521 0%, #3a0d27 100%); */
  height: 100vh;
  width: 100vw;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 200;
  background-color: 'rgba(171,197,170, 0.2)';
}

canvas {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#whiteboard-canvas {
  width: 100%;
  height: 30%;
}

.color-selector {
  display: flex;
  justify-content: space-between;
  bottom: 0px;
}

.marker {
  width: 20px;
  height: 20px;
  margin: 0;
  box-sizing: border-box;
  transition: box-shadow 113ms, transform 100ms;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  transform: translate(-1px, -1px);
}

.marker.selected {
  box-shadow: none;
  transform: translate(0, 0);
}

.bottom-notification {
  margin-bottom: 30px;
}

h4 {
  margin: 15px 15px;
}
a {
  text-decoration: none;
  color: #27c5f9;
}

label {
  display: block;
}

nav a {
  display: inline-block;
  margin: 1em;
}

form div {
  margin: 1em;
  display: inline-block;
}

/* Slideshow */
html,
body,
div,
span,
i,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
body {
  line-height: 1;
}
ul {
  list-style: none;
}
.image1 {
  background: #2980b9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffffff,
    #6dd5fa,
    #2980b9
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #6dd5fa,
    #2980b9
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  width: 100vw;
  height: 100%;
  position: absolute;
  background-size: cover;
}

.slide {
  /* display: none; */
  width: 100%;
  height: 500px;
  position: absolute;
}
.open {
  display: block;
}

.addButton {
  margin-top: 15px !important;
}

.bottomButton {
  position: absolute;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  top: 540px;
}
.bottomButton li {
  display: inline-block;
  margin: 0 5px;
}
.bottomButton li i {
  color: #fff;
  font-size: 15px;
}
.select {
  background-color: #fff;
  border-radius: 100%;
  opacity: 0.8;
}
[class*='MuiInputLabel-formControl-'] {
  top: 0;
  left: 15px !important;
}

#flashy {
  background: #fdc830; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f37335,
    #fdc830
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f37335,
    #fdc830
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 3;
  border: 0;
  color: 'white';
  height: 48;
  padding: '0 30px';
  box-shadow: '0 3px 5px 2px rgba(255, 105, 135, .3)';
}

#icon-attribution {
  font-size: 12px;
}
